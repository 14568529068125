import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Rating from '../components/rating'
import RatingHollow from '../components/rating-hollow'
import Img from 'gatsby-image'
import SanityImage from 'gatsby-plugin-sanity-image'
import tw, { theme, css } from 'twin.macro'
import Tilt from 'react-parallax-tilt'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    file(relativePath: { eq: "ajmal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    learnings: allSanityLearn(
      sort: { fields: [_updatedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          rating
          sourceLink {
            url
            cta
          }
          learnings {
            mainImage {
              ...ImageWithPreview
              caption
              alt
            }
            learnedAt
            title
            rating
            slug {
              current
            }
            _rawLearnContent(resolveReferences: { maxDepth: 5 })
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          status
          textColor {
            hex
          }
          bgColor {
            hex
          }
          slug {
            current
          }
          _updatedAt
          categories {
            _id
            title
            slug {
              current
            }
          }
          mainImage {
            ...ImageWithPreview
            caption
            alt
          }
        }
      }
    }
  }
`

const breakpoints = [640, 768, 1024]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const IndexPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const learningNodes = (data || {}).learnings
    ? mapEdgesToNodes(data.learnings)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} />
      <div
        tw="grid mt-5 pt-5 md:mt-16 md:pt-20"
        css={{
          gridTemplateColumns: '1fr min(1024px, calc(100% - 48px)) 1fr',
          gridRowGap: 8,
          '& > *': {
            gridColumn: 2,
          },
        }}
      >
        <div tw="mt-10 md:mt-0 align-middle md:place-self-center justify-between w-full">
          <div tw="flex flex-col-reverse md:flex-row md:items-center">
            <div tw="md:w-2/3 font-serif mt-8 md:mt-0 md:pr-8">
              <h1 tw="text-3xl lg:text-4xl xl:text-5xl font-semibold lg:font-semibold">
                Hi, I’m Ajmal 👋🏼
              </h1>
              <p tw="mt-2 prose prose-lg xl:prose-xl">
                Always gearing myself to be in the flow state & play mode. Currently exploring
                living with meaningful productivity.
              </p>
              <div
                className="btn"
                tw="mt-4 flex flex-col sm:flex-row text-center space-y-4 sm:space-y-0 sm:space-x-4"
              >
                <Link to="/projects">
                  <div
                    tw="rounded-full py-2 px-6 font-medium font-sans"
                    css={{
                      backgroundColor: `${theme`colors.accent`}`,
                      color: `var(--text-cta)`,
                      boxShadow:
                        '0 0 0 1px var(--color-primary), 0 1px 1px 1px var(--color-accent)',
                      '&:active': {
                        boxShadow:
                          '0 1px 1px 1px var(--color-accent), inset 0 2px 1px 1px var(--color-primary), inset 0 1px 2px 1px var(--bg-primary)',
                      },
                      '&:hover, &:focus': {
                        backgroundColor: `${theme`colors.primary`}`,
                      },
                    }}
                  >
                    View Projects
                  </div>
                </Link>
                <Link to="/notes">
                  <div
                    tw="rounded-full py-2 px-6 font-medium font-sans"
                    css={{
                      backgroundColor: 'transparent',
                      color: `var(--text-stronger)`,
                      boxShadow: '0 0 0 1px var(--color-border), 0 1px 1px 1px var(--color-border)',
                      '&:active': {
                        boxShadow:
                          '0 0 0 1px var(--color-border), inset 0 1px 2px var(--bg-darker), inset 0 -1px 1px var(--bg-primary)',
                        color: 'var(--text-softer)',
                      },
                      '&:hover, &:focus': {
                        backgroundColor: 'var(--bg-secondary)',
                      },
                    }}
                  >
                    Read Notes
                  </div>
                </Link>
              </div>
            </div>
            <div tw="md:w-1/3">
              <Tilt
                tiltReverse={true}
                tiltMaxAngleX="20.0"
                tiltMaxAngleY="20.0"
                gyroscope={true}
                glareMaxOpacity="0.2"
                glareReverse={true}
                glareEnable={false}
              >
                <Img
                  tw="rounded-full max-w-xs mx-auto md:ml-auto w-56"
                  fluid={data.file.childImageSharp.fluid}
                  alt="Ajmal Afif"
                />
              </Tilt>
            </div>
          </div>
        </div>
      </div>
      <section tw="w-full">
        <div
          tw="mt-16 pt-10 md:pt-20 mx-6 md:mx-auto grid grid-cols-1 md:grid-cols-2 font-serif border-t"
          css={{
            width: 'min(1024px, calc(100% - 48px))',
            gridRowGap: 8,
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <h2 tw="text-3xl lg:text-4xl col-span-1 font-semibold">Recently Learning</h2>
          <p tw="col-span-1 text-softer prose prose-lg xl:prose-xl">
            These are some of the recent snippets and snapshots that I document whenever I am
            learning. It can be anything that is fresh & new, or something I have been chipping away
            for awhile in pursuit of mastery.
          </p>
        </div>
      </section>
      <main
        css={{
          position: 'relative',
        }}
      >
        <div
          css={{
            background:
              'linear-gradient(to top, var(--bg-primary) 0%, var(--bg-primary) 15%, var(--bg-primary) 25%, transparent 100%) !important',
            content: "' '",
            position: 'absolute',
            width: '100%',
            height: '10vh',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9,
            [mq[0]]: {
              background:
                'linear-gradient(to top, var(--bg-primary) 0%, var(--bg-primary) 5%, var(--bg-primary) 25%, transparent 100%) !important',
              content: "' '",
              position: 'absolute',
              left: 0,
              width: '100%',
              bottom: 0,
              height: '20vh',
              right: 0,
              zIndex: 9,
            },
          }}
        ></div>
        <div tw="mt-10 md:mt-24 -mb-12 mx-auto" css={{ width: 'min(1240px, calc(100% - 48px))' }}>
          {learningNodes && (
            <div tw="">
              <ResponsiveMasonry columnCountBreakPoints={{ 640: 1, 768: 2, 1024: 3 }}>
                <Masonry gutter={'1.5rem'}>
                  {learningNodes.map(learning => (
                    <>
                      <Link to={'learn/' + learning.slug.current} tw="inline-block">
                        <div
                          key={learning.id}
                          className="card"
                          tw="w-full md:max-w-sm rounded-xl overflow-hidden inline-block border transition ease-in-out duration-150 hover:shadow-md hover:bg-gradient-to-t via-gray-200 scale-100 hover:scale-105 transform-gpu"
                          css={{
                            borderColor: `${theme`borderColor.accent`}`,
                          }}
                        >
                          <div tw="py-6 px-4">
                            <div tw="flex flex-col">
                              {learning.status && (
                                <div tw="flex flex-row justify-between mb-8">
                                  {learning.status && (
                                    <div
                                      tw="px-2 py-1 text-xs font-medium rounded-md font-sans"
                                      css={{
                                        backgroundColor: `${learning.bgColor.hex}`,
                                        color: `${learning.textColor.hex}`,
                                        width: 'fit-content',
                                      }}
                                    >
                                      {learning.status ? `${learning.status}` : ' '}
                                    </div>
                                  )}
                                  {learning.rating > 0 && (
                                    <figure tw="flex items-center">
                                      <div tw="inline-flex space-x-1">
                                        <Rating {...learning.rating} rating={learning.rating} />
                                        <RatingHollow
                                          {...learning.rating}
                                          rating={learning.rating}
                                        />
                                      </div>
                                      <figcaption tw="font-sans sr-only">
                                        Rating: {learning.rating} out of 5
                                      </figcaption>
                                    </figure>
                                  )}
                                </div>
                              )}
                              <h3 tw="prose text-2xl font-serif">{learning.title}</h3>
                            </div>
                            <p tw="prose text-lg mt-4 text-softer font-serif">
                              {toPlainText(learning._rawExcerpt)}
                            </p>
                            {learning.categories && (
                              <ul tw="list-none flex flex-wrap mt-4 text-sm text-gray-400 whitespace-nowrap">
                                {learning.categories.map(category => (
                                  <li tw="flex-initial mr-2 lowercase inline-block">
                                    #{category.title}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                          {learning.mainImage && !learning.mainImage.asset._id.includes('png') && (
                            <SanityImage
                              {...learning.mainImage}
                              config={{ fit: 'max' }}
                              css={{
                                '&[data-lqip]': {
                                  width: '100%',
                                },
                                '&[data-loading]': {
                                  width: '100%',
                                  bottom: '-20px',
                                },
                              }}
                              tw="max-h-full object-contain m-auto border-t w-full bg-white"
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                          {learning.mainImage && learning.mainImage.asset._id.includes('png') && (
                            <SanityImage
                              {...learning.mainImage}
                              config={{ fit: 'max' }}
                              css={{
                                '&[data-lqip]': {
                                  width: '100%',
                                },
                                '&[data-loading]': {
                                  width: '100%',
                                  bottom: '-20px',
                                },
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </div>
                      </Link>
                    </>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
